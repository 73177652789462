<template>
  <div class="auth" v-loading="loading"  element-loading-background="rgba(255, 255, 255, 0.5)">
    <Header :title="'证件'" :back="true"/>
    <div class="box-items">
      <div class="title-pwd">为保护您的资金安全，首次提现或转账到其他账户需上传身份证照片以核实身份</div>
      <el-upload
        class="avatar-uploader"
        action="#"
        :show-file-list="false"
        :auto-upload="false"
        :on-change="beforeAvatarUploadF">
        <img v-if="imageUrlFront" :src="imageUrlFront" class="avatar">
        <img v-else src="@/assets/img/front.png" class="avatar">
      </el-upload>
      <div class="ptips"> 点击上传身份证人像面</div>
      <el-upload
        class="avatar-uploader"
        action="#"
        :show-file-list="false"
        :auto-upload="false"
        :on-change="beforeAvatarUploadB">
        <img v-if="imageUrlBack" :src="imageUrlBack" class="avatar">
        <img v-else src="@/assets/img/back.png" class="avatar">
      </el-upload>
      <div class="ptips"> 点击上传身份证国徽面</div>
      <button class="defined-btn" style="width:95%" @click="updateUserImg">提交</button>
    </div>
    <div class="xieyi">为保护您的隐私，不会将信息透露给第三方</div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return{
      loading:false,
     imageUrlBack: '',
     imageUrlFront:'',
     frontBase:'',
     backBase:''
    }
  },
  created(){
   
  },
  methods:{
    beforeAvatarUploadF(file) {
      this.imageUrlFront = URL.createObjectURL(file.raw);
      this.fileToBase64(file.raw).then(res=>{
        this.frontBase = res
      })
    },
    beforeAvatarUploadB(file) {
      this.imageUrlBack = URL.createObjectURL(file.raw);
      this.fileToBase64(file.raw).then(res=>{
        this.backBase = res
      })
    },
    fileToBase64(file){
      return new Promise((resolve)=>{
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload=(e)=>{
          resolve(e.target.result)
        }
      })
    },
    updateUserImg(){
      let params={
        front:this.frontBase,
        back:this.backBase,
        type:'cardimg'
      }
      this.loading = true
      this.$store
        .dispatch('auth/validationsAuth',params)
        .then((res) => {
          if(res.msg=='身份证照片已提交'){
            this.$message({
              offset: 100,
              type: 'success',
              message: res.msg,
              customClass:'message-tips'
            });
            this.$router.push({name:"transferout"})
          }
        }).finally(()=>{
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.auth{
  .title-pwd{
    // background: #ffffff;
    padding: 1rem 5%;
    color: #464646;
    line-height: 2;
    font-size: .9rem;
  }
  .box-items{
    margin-top:2.5%
  }
  .form-items{
    margin: 1rem auto 0px;
  }
  .checkbox-box{
    font-size: .8rem;
    color: #464646;
    margin-top:3px;
   
  }
}
.draw-box{
  padding:2% 3%;
  :not(:first-child)div{
   margin-bottom:10px;
    margin-left:5%;
    margin-right:5%;
    
  }
  .close-icon{
    text-align: right;
    font-size: 1.5rem;
    margin-bottom:0px !important
  }
}
.draw-title{
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #989898;
  text-align: center;
  padding-top:5px;
  padding-bottom:5px
}
.code-btn{
  position: absolute;
  right: 20px;
  top:43px;
  z-index: 2;
  font-size: .8rem;
  cursor: pointer;
}
.avatar-uploader{
  text-align: center;
}
.avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin: 0px auto;
    

  }

  .avatar {
    display: block;
    width: 220px;
  }
  .ptips{
    text-align: center;
    font-size: .9rem;
    color: #464646;
    margin-bottom: 3rem;
  }
  .xieyi{
    color: #989898;
    font-size: .8rem;
    padding:2px 14%;
    text-align: center;
    margin-top:1rem
  }
</style>