var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "auth",
      attrs: { "element-loading-background": "rgba(255, 255, 255, 0.5)" },
    },
    [
      _c("Header", { attrs: { title: "证件", back: true } }),
      _c(
        "div",
        { staticClass: "box-items" },
        [
          _c("div", { staticClass: "title-pwd" }, [
            _vm._v(
              "为保护您的资金安全，首次提现或转账到其他账户需上传身份证照片以核实身份"
            ),
          ]),
          _c(
            "el-upload",
            {
              staticClass: "avatar-uploader",
              attrs: {
                action: "#",
                "show-file-list": false,
                "auto-upload": false,
                "on-change": _vm.beforeAvatarUploadF,
              },
            },
            [
              _vm.imageUrlFront
                ? _c("img", {
                    staticClass: "avatar",
                    attrs: { src: _vm.imageUrlFront },
                  })
                : _c("img", {
                    staticClass: "avatar",
                    attrs: { src: require("@/assets/img/front.png") },
                  }),
            ]
          ),
          _c("div", { staticClass: "ptips" }, [
            _vm._v(" 点击上传身份证人像面"),
          ]),
          _c(
            "el-upload",
            {
              staticClass: "avatar-uploader",
              attrs: {
                action: "#",
                "show-file-list": false,
                "auto-upload": false,
                "on-change": _vm.beforeAvatarUploadB,
              },
            },
            [
              _vm.imageUrlBack
                ? _c("img", {
                    staticClass: "avatar",
                    attrs: { src: _vm.imageUrlBack },
                  })
                : _c("img", {
                    staticClass: "avatar",
                    attrs: { src: require("@/assets/img/back.png") },
                  }),
            ]
          ),
          _c("div", { staticClass: "ptips" }, [
            _vm._v(" 点击上传身份证国徽面"),
          ]),
          _c(
            "button",
            {
              staticClass: "defined-btn",
              staticStyle: { width: "95%" },
              on: { click: _vm.updateUserImg },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "xieyi" }, [
        _vm._v("为保护您的隐私，不会将信息透露给第三方"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }